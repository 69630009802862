import styles from "./styles.module.scss";

const Footer = () => {
	return (
		<footer className={styles.footer}>
			<div className="container">
				<div className="row">
					{/* <div className="col-md-4">
						<h4>About Us</h4>
						<p>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam
							viverra euismod odio, gravida pellentesque urna varius vitae.
						</p>
					</div> */}
					<div className="col-md-6">
						<h4>Contact Us</h4>
						<ul className="list-unstyled">
							<li>Email: redcarpetclub@hotmail.com</li>
							{/* <li>Phone: 555-555-5555</li>
							<li>Address: 123 Main Street, Suite 200</li> */}
						</ul>
					</div>
					<div className="col-md-6">
						<h4>Follow Us</h4>
						<ul className="list-inline">
							{/* <li className="list-inline-item">
								<a href="#">
									<i className="fab fa-facebook-f"></i>
								</a>
							</li> */}
							{/* <li className="list-inline-item">
								<a href="#">
									<i className="fab fa-twitter"></i>
								</a>
							</li> */}
							<li className="list-inline-item">
								<a href="https://www.instagram.com/redcarpet.club">
									<i className="fab fa-instagram"></i>
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
