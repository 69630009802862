import { FC } from "react";
import { Footer } from "..";

import styles from "./styles.module.scss";
import OffcanvasNavbar from "../OffcanvasNavbar";

interface Props {
	children: any;
}

const Layout: FC<Props> = ({ children }) => {
	return (
		<>
			{/* <Header /> */}
			<OffcanvasNavbar />
			<section id="page-content" className={styles.layout}>
				<div className="container">{children}</div>
			</section>
			<Footer />
		</>
	);
};

export default Layout;
