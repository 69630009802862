import styles from ".//styles.module.scss";

const WelcomeCard = () => {
	return (
		<div className={styles.welcomeCard}>
			<div className={styles.heading}>
				<h1>
					Welcome to the Luxurious Private{" "}
					<span className={styles.text}>Red Carpet</span> Club
				</h1>
			</div>
			<p>
				As a member of our prestigious club, you will have the opportunity to
				indulge in the finest luxuries and immerse yourself in a world of class
				and sophistication. From exclusive events to VIP experiences and special
				offers, we have curated a membership that promises to exceed all of your
				expectations. We invite you to join us on the red carpet and discover
				the ultimate in luxury living. Welcome to the club!
			</p>
			{/* <p></p>
			<div style={{ clear: "both" }}></div> */}
			<div>
				<img
					src="../../../assets/images/background.jpg"
					alt="rcc"
					className={styles.thumbnail}
				/>
			</div>
			{/* <h1>Welcome to the Luxurious Private Red Carpet Club</h1>
			<p>
				Join our exclusive club and experience the best in luxury and class.
			</p> */}
		</div>
	);
};

export default WelcomeCard;
