import ComingSoonCard from "../../components/Cards/ComingSoon";
import WelcomeCard from "../../components/Cards/Welcome";
import { SEO } from "../../components";
// import ProfilePage from "../Profile";

const HomePage = () => {
	return (
		<div>
			{/* <Helmet>
				<title>Home | Red Carpet Club</title>
				<meta name="description" content="" />
			</Helmet> */}
			<SEO
				title={"Home Page"}
				description={"Red Carept Club Home Page"}
				name={"Red Carept Club"}
				type={"home"}
			/>
			<div className="container">
				<ComingSoonCard />
				<WelcomeCard />
				{/* <ProfilePage /> */}
			</div>
		</div>
	);
};

export default HomePage;
