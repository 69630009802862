import Sparkles from "react-sparkle";
import styles from "./styles.module.scss";

const ComingSoonCard = () => {
	return (
		<div className={styles.comingSoon}>
			<div className={styles.heading}>
				<h1>LAUNCHING SOON</h1>
				<Sparkles color="#d48f8b" overflowPx={8} />
			</div>

			{/* <h1>Welcome to the Luxurious Private Red Carpet Club</h1>
			<p>
				Join our exclusive club and experience the best in luxury and class.
			</p> */}
		</div>
	);
};

export default ComingSoonCard;
