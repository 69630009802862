import { PageTitle, SEO } from "../../components";
import styles from "./styles.module.scss";

const AboutPage = () => {
	return (
		<>
			<SEO
				title={"About"}
				description={"Red Carept Club About Page"}
				name={"Red Carept Club"}
				type={"about"}
			/>
			<div className={styles.about}>
				<PageTitle />
				<p>
					The Red Carpet Club is an exclusive online community designed for
					individuals who want to feel like a celebrity. Our members are premium
					and sophisticated individuals who are looking for an elevated and
					luxurious experience. We are a one-stop-shop for all things luxury,
					offering a wide range of exclusive benefits and privileges.
				</p>
				<p>
					Our site features a user-friendly interface, making it easy for
					members to access all of the benefits and services we have to offer.
					Members can create a personal profile, browse and RSVP to upcoming
					events, and take advantage of special deals and discounts.
				</p>
				<p>
					Our exclusive events include glamorous parties, high-end dining
					experiences, and exclusive access to VIP areas at concerts and other
					events. In addition, our concierge service can assist members with
					everything from travel arrangements to reservations at the hottest
					restaurants.
				</p>
				<p>
					At the Red Carpet Club, we pride ourselves on providing our members
					with the ultimate in luxury and style. Whether you're looking to
					network with like-minded individuals or simply indulge in a little bit
					of pampering, we've got you covered. Join today and experience the red
					carpet treatment.
				</p>
			</div>
		</>
	);
};

export default AboutPage;
