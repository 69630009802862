import styles from "./styles.module.scss";

const HeaderTitle = () => {
	return (
		<div className={styles.headerTitle}>
			<span className={styles.name}>Red Carpet</span>{" "}
			<span className={styles.club}>Club</span>
		</div>
	);
};

export default HeaderTitle;
