import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { AboutPage, ContactPage, HomePage } from "./pages";
import { Layout } from "./components";

import "./App.css";

function App() {
	const helmetContext = {};
	return (
		<HelmetProvider context={helmetContext}>
			<Router>
				<Routes>
					<Route
						path="/"
						element={
							<Layout>
								<HomePage />
							</Layout>
						}
					/>
					<Route
						path="/about"
						element={
							<Layout>
								<AboutPage />
							</Layout>
						}
					/>
					<Route
						path="/contact"
						element={
							<Layout>
								<ContactPage />
							</Layout>
						}
					/>
				</Routes>
			</Router>
		</HelmetProvider>
	);
}

export default App;
