import clsx from "clsx";
import { FC } from "react";
// import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
// import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
// import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";

import HeaderTitle from "../HeaderTitle";
import { Link } from "react-router-dom";

import styles from "./styles.module.scss";
import "./styles.css";

interface Props {
	title?: string;
}

const OffcanvasNavbar: FC<Props> = ({ title }) => {
	// const userName = "Sohail Abdul Sattar";
	const size = "sm"; // false;
	return (
		<>
			<Navbar bg="light" expand={size} className={clsx("mb-3", styles.navbar)}>
				<Container fluid>
					<Link className="nav-link" to="/">
						<HeaderTitle />
					</Link>
					<Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${size}`} />
					<Navbar.Offcanvas
						id={`offcanvasNavbar-expand-${size}`}
						aria-labelledby={`offcanvasNavbarLabel-expand-${size}`}
						placement="end"
					>
						<Offcanvas.Header closeButton>
							{/* <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${size}`}>
								{userName}
							</Offcanvas.Title> */}
						</Offcanvas.Header>
						<Offcanvas.Body>
							<Nav className="justify-content-end flex-grow-1 pe-3">
								<Link className="nav-link" to="/">
									Home
								</Link>
								<Link className="nav-link" to="/about">
									About
								</Link>
								{/* <NavDropdown
									title="Dropdown"
									id={`offcanvasNavbarDropdown-expand-${size}`}
								>
									<NavDropdown.Item href="#action3">Action</NavDropdown.Item>
									<NavDropdown.Item href="#action4">
										Another action
									</NavDropdown.Item>
									<NavDropdown.Divider />
									<NavDropdown.Item href="#action5">
										Something else here
									</NavDropdown.Item>
								</NavDropdown> */}
							</Nav>
							{/* <Form className="d-flex">
								<Form.Control
									type="search"
									placeholder="Search"
									className="me-2"
									aria-label="Search"
								/>
								<Button variant="outline-success">Search</Button>
							</Form> */}
						</Offcanvas.Body>
					</Navbar.Offcanvas>
				</Container>
			</Navbar>
		</>
	);
};

export default OffcanvasNavbar;
